import './app.css';
import {useEffect, useState} from "react";
import hra from './media/hra.jpg'
import programu from './media/programu.jpg'
import mapu from './media/mapu.jpg'
import grafu from './media/grafu.jpg'
import receptu from './media/receptu.jpg'
import pripravu from './media/pripravu.jpg'
import rss from './media/rss.jpg'
import studu from './media/studu.jpg'

const list = ['hra', 'mapu', 'grafu', 'studu', 'programu', 'receptu', 'pripravu', 'rss']
const jeme = '.jeme.cz'
const https = 'https://'

let last = -1

function getNext(): string {
    if (last + 1 === list.length) {
        last = 0;
        return list[0];
    }
    last++;
    return list[last]
}

function getImg() {
    switch (list[last]) {
        case 'hra':
            return hra;
        case 'programu':
            return programu;
        case 'mapu':
            return mapu;
        case 'grafu':
            return grafu;
        case 'receptu':
            return receptu;
        case 'pripravu':
            return pripravu;
        case 'rss':
            return rss;
        case 'studu':
            return studu
        default:
            return hra;
    }
}

export default function App() {
    const [p1Content, setP1Content] = useState('');
    const [p2Content, setP2Content] = useState(list[0]);
    const [p3Content, setP3Content] = useState('');
    const [active, setActive] = useState('p1');

    useEffect(() => {
        function setActiveContent(content: string) {
            if (active === 'p1') {
                setP2Content(content)
            } else if (active === 'p2') {
                setP3Content(content)
            } else {
                setP1Content(content)
            }
        }

        const interval = setInterval(() => {
            const p1: HTMLParagraphElement = document.getElementById('p1') as HTMLParagraphElement
            const p2: HTMLParagraphElement = document.getElementById('p2') as HTMLParagraphElement;
            const p3: HTMLParagraphElement = document.getElementById('p3') as HTMLParagraphElement;
            const a: HTMLAnchorElement = document.getElementById('a') as HTMLAnchorElement;
            const body: HTMLBodyElement = document.getElementById('body') as HTMLBodyElement;

            setActiveContent(getNext())
            body.style.backgroundImage = 'url("' + getImg() + '")'
            if (!(p1 && p2 && p3 && a)) return;

            a.href = https + list[last] + jeme
            if (active === 'p1') {
                setActive('p2');
                p1.className = 'prefix top'
                p2.className = 'prefix active'
                p3.className = 'prefix bottom'
            } else if (active === 'p2') {
                setActive('p3');
                p1.className = 'prefix bottom'
                p2.className = 'prefix top'
                p3.className = 'prefix active'
            } else {
                setActive('p1');
                p1.className = 'prefix active'
                p2.className = 'prefix bottom'
                p3.className = 'prefix top'
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [active]);

    const body: HTMLBodyElement = document.getElementById('body') as HTMLBodyElement;
    if (!body) return (<></>);
    body.style.backgroundImage = 'url("' + getImg() + '")';

    return (
        <>
            <div className={'bgr'}></div>
            <a id={'a'} href={https + list[0] + jeme}>
                <div className={'box'}>
                    <p id={'p1'} className={'prefix top'}>{p1Content}</p>
                    <p id={'p2'} className={'prefix active'}>{p2Content}</p>
                    <p id={'p3'} className={'prefix bottom'}>{p3Content}</p>
                    <p className={'jeme'}>{jeme}</p>
                </div>
            </a>
        </>
    );
}